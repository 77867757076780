<template>
	<div class="page">
		<el-button type="primary" size="mini" icon="el-icon-plus" @click="onCreate">新增
		</el-button>

		<el-table :data="tableData" border stripe :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small"
			max-height="500px" class="margin-t-10" style="width: 100%;" row-key="id"
			:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
			<el-table-column prop="id" label="ID" width="60px" align="center"></el-table-column>
			<el-table-column prop="title" label="角色名称" align="center"></el-table-column>
			<el-table-column prop="name" label="角色标识" align="center"></el-table-column>
			<el-table-column prop="sort" label="排序" align="center">
				<template slot-scope="scope">
					<el-popover placement="top" trigger="manual" v-model="scope.row.sortShow">
						<el-link type="primary" slot="reference" @click="scope.row.sortShow = !scope.row.sortShow">
							{{scope.row.sort}} <i class="el-icon-edit"></i>
						</el-link>
						<div class="row-edit-input">
							<el-input v-model="scope.row.sort" placeholder="请输入排序"></el-input>
						</div>
						<div style="text-align: right; margin: 0">
							<el-button size="mini" type="text" @click="scope.row.sortShow = false">取消</el-button>
							<el-button type="primary" size="mini"
								@click="onQuick(scope.row.id, 'sort', scope.row.sort)">确定</el-button>
						</div>
					</el-popover>
				</template>
			</el-table-column>
			<el-table-column label="操作" align="center">
				<template slot-scope="scope">
					<el-link @click="addChild(scope.row)" type="primary" class="margin-r-10">添加子角色</el-link>
					<el-link @click="onEdit(scope.row)" type="primary" class="margin-r-10">编辑</el-link>
				</template>
			</el-table-column>
		</el-table>

		<el-dialog title="编辑信息" :visible.sync="infoShow" width="40%" :before-close="resetInfo" id="RolesDialog"
			:close-on-click-modal="false">
			<el-form :model="info" ref="info" :rules="rules" label-width="80px" :inline="false" size="small">
				<el-form-item label="角色名称" prop="title">
					<el-input v-model="info.title"></el-input>
				</el-form-item>
				<el-form-item label="标识" prop="name">
					<el-input v-model="info.name"></el-input>
				</el-form-item>
				<el-form-item label="排序" prop="sort">
					<el-input v-model="info.sort"></el-input>
				</el-form-item>
				<el-form-item label="权限">
					<el-cascader v-model="info.permission_groups" :options="permissionCascade" collapse-tags
						:props="{ multiple: true }" style="width: 100%;"></el-cascader>
				</el-form-item>
			</el-form>
			<span slot="footer">
				<el-button @click="resetInfo">取消</el-button>
				<el-button :loading="submitting" type="primary" @click="subInfo">提交</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	export default {
		name: "role",
		data() {
			return {
				tableData: [],
				infoShow: false,
				saveType: 1, // 1新增 2编辑
				submitting: false,
				info: {
					title: '',
					name: '',
					group_name_all: [],
					sort: 1,
					permission_groups: [],
				},
				permissionCascade: [],
				roleCascade: [],
				rules: {
					title: [{
						required: true,
						message: '请填写角色名称',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请填写角色标识',
						trigger: 'blur'
					}],
				},
			}
		},
		mounted() {
			this.getTabeData()
			this.getPermissionCascade()
		},
		methods: {
			getTabeData() {
				let data = this.filter
				this.$util.get('roles', data).then(res => {
					this.tableData = res
				})
			},
			getPermissionCascade() {
				this.$util.get('cascade', {
					model: 'Permission'
				}).then(res => {
					this.permissionCascade = res
				})
			},
			getRoleCascade() {
				this.$util.get('cascade', {
					model: 'Role'
				}).then(res => {
					this.roleCascade = res
				})
			},
			onSearch() {
				this.page = 1
				this.getTabeData()
			},
			pageChange(page) {
				this.page = page
				this.getTableData()
			},
			onCreate() {
				this.saveType = 1
				this.infoShow = true
			},
			onEdit(row) {
				this.saveType = 2
				this.info = {
					id: row.id,
					title: row.title,
					name: row.name,
					sort: row.sort,
					group_name_all: row.group_name_all,
					permission_groups: row.permission_groups
				}
				this.infoShow = true
			},
			onQuick(id, field, val) {
				this.$util.put('roles/quick/' + id, {
					field: field,
					value: val
				}).then(() => {
					this.getTabeData()
				})
			},
			subInfo() {
				this.$refs['info'].validate((valid) => {
					if (!valid) return
					
					this.submitting = true
					
					if (this.saveType == 1) {
						this.$util.post('roles', this.info).then(() => {
							this.getTabeData()
							this.infoShow = false
						}).finally(() => this.submitting = false)
					} else {
						this.$util.put('roles/' + this.info.id, this.info).then(() => {
							this.getTabeData()
							this.infoShow = false
						}).finally(() => this.submitting = false)
					}
				})
			},
			resetInfo() {
				this.info = {
					title: '',
					name: '',
					sort: 1,
					group_name_all: [],
					permission_groups: [],
				}
				this.infoShow = false
			},
			addChild(row) {
				this.getRoleCascade()
				this.saveType = 1
				this.infoShow = true
				let groups = row.group_name_all ? [...row.group_name_all] : []
				groups.push(row.name)
				this.info.group_name_all = groups
			}
		}
	}
</script>

<style scoped>
	.icon-box {
		display: inline-block;
		padding: 0 15px;
	}

	.icon-list {
		border: #eee 1px solid;
		padding: 0;
		overflow: hidden;
		border-right: none;
		border-bottom: none;
		height: 400px;
		overflow-y: scroll;
	}

	.icon-list li {
		float: left;
		width: 10%;
		list-style: none;
		text-align: center;
		padding: 20px;
		box-sizing: border-box;
		border-right: #eee 1px solid;
		border-bottom: #eee 1px solid;
	}

	.icon-list li.on {
		background-color: #eee;
	}

	.icon-list li i {
		display: block;
		font-size: 24px;
	}

	.icon-list li span span {
		display: none;
	}

	.row-edit-input {
		margin-bottom: 10px;
	}

	.el-button+.btn-pop {
		margin-left: 10px;
	}
</style>
<style>
	#RolesDialog .el-form .el-form-item {
		margin: 20px 0;
	}
</style>
